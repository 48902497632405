import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Layout } from '../components/shared/Layout';

const DisclaimerPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto max-w-xl">
            <h1>Contact</h1>
            <h2>Any questions?</h2>
            <div className="pt-16 pb-8">
              <p>
                Mail us at{' '}
                <a href="mailto:thehague@awesomefoundation.org">
                  thehague@awesomefoundation.org
                </a>
              </p>
            </div>
            <div className="py-16">
              <p>
                Join the Telegram group for direct contact:
                <br />
                <a
                  href="https://t.me/joinchat/AHrH1hlCr1e2JzMbO0X5xw"
                  className="block m-4"
                >
                  <FontAwesomeIcon icon={faTelegram} size="2x" />
                </a>
              </p>
            </div>
            <h2>You can also connect using social media:</h2>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default DisclaimerPage;
